/* basic styles */
.skeleton {
    background-color: #666;
    margin: 5px 0;
    border-radius: 4px;
}

.skeleton.text {
    width: 100%;
    height: 12px;
}
.skeleton.title {
    width: 100%;
    height: 20px;
    margin-bottom: 15px;
}
.skeleton.circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.skeleton.square {
    width: 100%;
    height: 100px;
}
.skeleton-wrapper {
    padding: 10px;
    border-radius: 4px;
    background-color: #282828;
    position: relative;
    overflow: hidden;
    padding-bottom: 0;
}
/* Different skeleton */
.skeleton-blog .square {
    height: 250px;
}
.skeleton-work .square {
    height: 300px;
}
.skeleton-single-work .square {
    height: 385px;
}

/* Shimmer animation */
.shimmer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.shimmer {
    width: 50%;
    height: 100%;
    background-color: rgb(255 255 255 / 3%);
    box-shadow: 0 0 30px 30px rgb(255 255 255 / 3%);
    transform: skewX(-20deg);
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        transform: translateX(-200%);
    }
    50% {
        transform: translateX(-60%);
    }
    100% {
        transform: translateX(200%);
    }
}
