@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600&family=Jost:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@400;700;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    background-color: #555555;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
a {
    text-decoration: none;
}
.primary-bg {
    background-color: #222;
}
.secondary-bg {
    background-color: #3a3a3a;
}
.primary-color,
a {
    color: #ededed;
}
.colorized {
    color: #04b4e0;
}
.colorized-bg {
    background-color: #04b4e0;
}
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 10px;
}
::-webkit-scrollbar-track {
    background: #4c4c4c;
}
