.lnr-arrow-left {
  font-size: 25px;
  width: 50px;
  margin-bottom: 30px;
  display: inline-block;
  transition: 0.3s cubic-bezier(0.4, 0, 0, 0.63);
}
.credential-list {
  padding: 0 20px 18px 30px;
  font-size: 13px;
  font-weight: 300;
}
.credential-list span {
  display: inline-block;
  background-color: #383838;
  border-radius: 5px;
  padding: 0px 6px;
  margin-bottom: 8px;
}
.lnr-arrow-left:hover {
  transform: translateX(10px);
}
.sw-image {
  width: 100%;
  max-height: 385px;
  overflow: hidden;
}
.sw-image img {
  width: 100%;
}
.sw-heading {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sw-heading a {
  margin-left: 20px;
  font-size: 15px;
  display: inline-block;
  transition: 0.2s;
  margin-top: 10px;
}

.sw-heading a:hover {
  transform: scale(1.1);
}
p.work-overview {
  margin: 30px 0;
}

h3.work-perpuse-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

p.peoject-perpuse ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p.peoject-perpuse {
  padding-left: 30px;
  line-height: 28px;
}
p.peoject-perpuse span {
  display: inline-block;
  margin-right: 8px;
  color: #61b4f1;
}

.project-desc {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-gap: 20px;
}
.project-desc.true {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0px;
  margin-bottom: 40px;
}
.project-links-container {
  background-color: #2a2a2a;
  padding: 25px;
}
.project-links-container h4 {
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.project-links-container a {
  margin-bottom: 10px;
  color: #409fde;
  display: flex;
  font-weight: 300;
  align-items: center;
  gap: 10px;
  width: max-content;
}
.tech-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}
.project-links-container p {
  margin-top: 20px;
}
.tech-container span {
  font-size: 12px;
  padding: 2px 13px;
  background-color: #383838;
  border-radius: 3px;
}
.project-links-container svg {
  font-size: 20px;
  color: #409fde;
}
table.work-desc-table td {
  padding: 2px;
  font-size: 14px;
  font-weight: 300;
  color: #ddd;
}
table.work-desc-table tr td:first-child {
  display: block;
  font-size: 14px;
  padding-top: 5px;
}
