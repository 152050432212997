.home-content {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: 90vh;
}
.home-name {
    font-weight: 500;
    font-size: clamp(37px, 8vw, 50px);
}
.home-title {
    font-size: 22px;
}
.home .page-container {
    padding: 0;
}
