.work-filter {
  display: flex;
  flex-wrap: wrap;
}
.work-filter button {
  background-color: transparent;
  color: rgb(209, 209, 209);
  border: none;
  padding: 5px 8px;
  cursor: pointer;
  margin-right: 8px;
}
.work-filter button.active {
  color: #fff;
}
.work-container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}
