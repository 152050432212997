.main-container {
    display: grid;
    grid-template-columns: 85px 400px auto;
    max-width: 1400px;
    margin: 0 auto;
    height: 100vh;
    overflow: hidden;
}
@media (min-width: 1400px) {
    .main-container {
        margin: 20px auto;
        height: calc(100vh - 40px);
        box-shadow: 0 0 70px -5px #0000008c;
        border-radius: 10px;
    }
}
.page-container {
    padding: 45px;
    min-height: 100vh;
}
.page-title {
    padding-bottom: 10px;
    font-size: clamp(26px, 7vw, 35px);
    font-weight: 500;
}
.sub-title {
    padding: 30px 0;
    font-size: clamp(20px, 7vw, 24px);
    font-weight: 500;
}
.scrollable-container {
    overflow-y: auto;
}

/* Responsive css */
/* ____________________ */
/* ____________________ */
/* ____________________ */

@media (max-width: 1270px) {
    .main-container {
        grid-template-columns: 85px 350px auto !important;
    }
}
@media (max-width: 1210px) {
    .main-container {
        display: grid;
        grid-template-columns: 85px 350px auto !important;
        max-width: 1400px;
        margin: 0 auto;
        height: 100vh;
        overflow: hidden;
    }
    .about-container {
        grid-template-columns: 1fr !important;
    }
    .edu-skill {
        display: grid;
        grid-template-columns: 1fr !important;
        grid-column-gap: 30px;
    }
}
@media (max-width: 1210px) {
    .bp-body {
        width: 100% !important;
        padding: 30px 0 !important;
    }
}
@media (max-width: 1050px) {
    .work-container {
        grid-template-columns: 1fr !important;
    }
    .blog-container {
        grid-template-columns: 1fr !important;
    }
    .contact-body {
        display: flex !important;
        flex-direction: column;
    }
    .address-boxes {
        order: 2;
    }
    .contact-form {
        order: 1;
        margin-bottom: 50px !important;
    }
    .project-desc {
        grid-template-columns: 1fr !important;
    }
}
@media (max-width: 1000px) {
    .wi-boxes {
        grid-template-columns: 1fr !important;
    }
}
@media (max-width: 890px) {
    .toggle-container {
        display: block !important;
    }
    .page-container {
        padding: 45px 25px !important;
    }
    .main-container {
        grid-template-columns: 1fr !important;
    }
    .profile {
        display: none !important;
    }
    .side-menu {
        position: fixed !important;
        right: 0 !important;
        top: 0 !important;
        width: 300px !important;
        margin-right: -300px !important;
        transition: 0.3s;
    }
    .side-menu-profile {
        display: block !important;
    }
    .side-menu nav a {
        flex-direction: row !important;
        padding-left: 20px !important;
        text-align: left !important;
        justify-content: flex-start !important;
        gap: 18px;
        height: 55px !important;
        align-items: center !important;
    }
    .side-menu nav a i {
        display: inline-block !important;
        margin: 0 6px !important;
        font-size: 22px !important;
    }
    .side-menu nav a span {
        display: inline-block !important;
        padding: 2px 0 !important;
    }
    .side-menu nav li {
        border-bottom: none !important;
    }
    .active.side-menu {
        margin-right: 0 !important;
        box-shadow: 0 -5px 30px #0000002b;
    }
    .sw-heading {
        display: block !important;
    }
    .sw-heading a {
        margin-right: 20px !important;
        margin-left: 0 !important;
    }
    .sw-heading h2 {
        margin-bottom: 10px !important;
    }
    p.peoject-perpuse {
        padding-left: 0 !important;
    }
    ::-webkit-scrollbar {
        width: 0 !important;
    }
}
@media (max-width: 460px) {
    .page-container {
        padding: 45px 15px !important;
    }
    .post-footer p {
        font-size: 12px !important;
        line-height: 30px !important;
    }
}
.lazy-load-image-background {
    width: 100%;
}
