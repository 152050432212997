.work-card {
    overflow: hidden;
    background-color: #2e2e2e96;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
}

.work-img-wrapper {
    height: 300px;
    overflow: hidden;
    background-position: top;
    background-size: cover;
    transition: 3s;
}
.work-card:hover .work-img-wrapper {
    background-position: bottom;
}

img.work-card-img {
    width: 100%;
    transition: 1.5s !important;
}
.card-browser span {
    display: inline-block;
    background-color: #ddd;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    margin-left: 5px;
}

h3.work-name {
    padding: 13px 0;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
}
/* .work-card:hover .work-card-img {
    transform: translateY(-30%);
} */
.project-category {
    display: block;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    color: #ddd;
    text-align: right;
    transform-origin: center center;
    font-size: 11px;
    padding: 3px 10px;
    letter-spacing: 2px;
}
