/* **************
 *************
 **********
 *****
 **
 Blog post */

.bp-header {
    background: #999;
    width: 100%;
    z-index: 0;
    max-height: 350px;
    overflow: hidden;
}

.bp-header img {
    width: 100%;
    margin-bottom: -7px;
    height: 350px;
    object-fit: cover;
}

.bp-body {
    position: relative;
    margin: -70px auto 0;
    background: #222;
    width: 90%;
    padding: 30px;
    z-index: 1;
}

.bp-body h3 {
    font-size: 15px;
    font-weight: 300;
    padding-bottom: 15px;
}

.bp-body h2 {
    font-size: clamp(20px, 7vw, 28px);
    font-weight: 400;
    padding-bottom: 15px;
}

.bp-body p {
    font-size: 15px;
    margin-bottom: 20px;
    font-weight: 300;
}
.bp-body p span {
    color: white;
}

.bp-body p.italic {
    border-left: 1px solid #ddd;
    padding-left: 30px;
    font-style: oblique;
}

.post-footer {
    background: #333;
    border: 1px solid #444;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    padding: 7px 15px;
}

.pf-left p {
    display: inline-block;
    margin-bottom: 0;
}

.pf-left p:first-child {
    padding-right: 10px;
    border-right: 1px solid #999;
    margin-right: 10px;
}

.pf-left p i {
    color: #999;
}

.pf-right {
    text-align: right;
}

.pf-right a {
    display: inline-block;
    background: #222;
    height: 30px;
    width: 30px;
    text-align: center;
    margin-left: 5px;
    transition: 0.3s;
}

.pf-right a:hover {
    background: #09c;
}

.pf-right a:hover i {
    color: white;
}

.pf-right a i {
    line-height: 30px;
    color: #999;
}

.post-keywords {
    margin-top: 15px;
}

.post-keywords a {
    background: #333;
    display: inline-block;
    padding: 5px 10px;
    font-size: 11px;
    margin-right: 4px;
    border-radius: 2px;
}
