.blog-card {
    width: 100%;
    overflow: hidden;
    background-color: #2e2e2e96;
    box-shadow: 0 0 10px #1c1c1cc7;
}

.blog-img-container {
    height: 250px;
    overflow: hidden;
}

.blog-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-title-wrapper {
    padding: 20px;
}

.blog-title-wrapper h3 {
    font-weight: 500;
}
