/* Contact  */
.contact-form .sub-title {
    padding-top: 0;
}

.contact-body {
    display: grid;
    grid-template-columns: 250px auto;
    margin-top: 20px;
    grid-column-gap: 30px;
}

.address-box {
    background: #333;
    display: grid;
    padding: 18px 0;
    place-items: center;
    margin-bottom: 16px;
}

.address-box i {
    font-size: 40px;
    padding-bottom: 10px;
}

.address-box h4 {
    font-size: 15px;
    font-weight: 300;
}

.in-style {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 22px;
    outline: none;
    border-radius: 3px;
    color: #444;
    border: 2px solid transparent;
}

.in-style::placeholder {
    font-style: oblique;
}

.in-style:focus {
    border: 2px solid #09c;
}

.contact-btn {
    background-color: #424242;
    color: #ddd;
    padding: 9px 32px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.4s;
    border: 0;
    border-radius: 3px;
}

.contact-btn:hover {
    background: #09c;
    color: white;
}
