.a-text p {
  margin-bottom: 15px;
  font-size: 14px;
  color: #ddd;
}
.about-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}
.a-address p {
  margin-bottom: 10px;
  font-size: 15px;
}

.a-address span {
  margin-right: 6px;
}

.what-ido {
  padding: 30px 0;
}

.wi-boxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.wi-box {
  margin-bottom: 30px;
}

.wi-box svg {
  font-size: 50px;
  color: #04b4e0;
}

.wi-box h4 {
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
}

/* Education */
.edu-skill {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
}

/* education start */

.ee-box {
  display: grid;
  grid-template-columns: 105px 1fr;
}

.ee-box-left {
  text-align: right;
  padding-right: 15px;
  border-right: 1px solid #3a3a3a;
}

.ee-box-left h4 {
  font-size: 15px;
  font-weight: 500;
  color: #aaa;
  padding-bottom: 10px;
}

.ee-box-left h5 {
  color: #888;
  font-weight: 400;
}

.ee-box-right {
  padding-left: 15px;
}

.ee-box-right h3 {
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}
.ee-box-right p {
  padding-bottom: 10px;
  font-size: 13px;
  color: #cfcfcf;
}
.ee-box p:last-child {
  padding-bottom: 25px;
}
.ee-box:last-child p:last-child {
  padding-bottom: 0;
}

/* education end */
/* Skill css start */
.skill-boxes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.single-skill-box {
  background-color: #2c2c2c;
  padding: 14px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.skill-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.single-skill-box p {
  font-size: 12px;
  color: #ddd;
}
.single-skill-box p span {
  color: #04b4e0;
}
.skill-level {
  display: flex;
  gap: 5px;
  align-items: center;
}
.skill-level > div {
  height: 30px;
  width: 5px;
  border-radius: 2px;
}
.year-level {
  background-color: #04b4e0;
}
.project-level {
  background: linear-gradient(244deg, #68b1d6, #b9b9b9);
}
/* Skill css end */

/* Testimonial section start. ............. */
.testimonial {
  padding: 30px 0;
}
.testimonial-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.testi-slider-btns {
  display: flex;
  gap: 15px;
}
.testi-slider-btns button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #3a3a3a;
  border: none;
  color: #fff;
  font-size: 20px;
}
.testi-slider-btns button.disabled {
  background-color: #2a2a2a;
  color: #818181;
  pointer-events: none;
  cursor: not-allowed;
}
.testi-slider-container {
  overflow: hidden;
}
.testi-boxes {
  display: grid;
  grid-template-columns: repeat(3, 100%);
  padding-top: 20px;
  transition: 0.5s;
}
.tesi-double-box-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.testi-box {
  border: 2px solid #444;
  border-radius: 15px;
  position: relative;
  padding: 70px 25px 25px;
  width: 100%;
  max-width: 500px;
  margin: 45px auto 0;
}

.testi-box img {
  height: 90px;
  width: 90px;
  border-radius: 100%;
  transform: translateX(-50%);
  left: 50%;
  top: -45px;
  display: block;
  position: absolute;
}

.testi-box p {
  font-style: oblique;
}

.testi-box h4 {
  padding: 20px 0 0px;
}

.testi-box h5 {
  font-weight: 200;
}

/* Testimonial section end. ............. */
