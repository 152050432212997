.side-menu {
    min-height: 100vh;
    overflow-y: auto;
    z-index: 900;
}
.scrollable-div {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 40px;
    background-color: #222;
}
.side-menu::-webkit-scrollbar,
.scrollable-div::-webkit-scrollbar {
    width: 0px;
}
.side-menu nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.side-menu nav li {
    margin-bottom: 2px;
}

.side-menu nav a svg {
    font-size: 30px;
}
.side-menu nav a span {
    font-size: 13px;
    margin-top: 4px;
}
.side-menu nav a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80px;
    background-color: #2a2a2a6b;
}

.side-menu nav a.active {
    background-color: #3a3a3a;
}
.side-menu nav a.active span {
    color: white;
}
/* ffffff */
.toggle-container {
    position: absolute;
    top: 10px;
    right: 15px;
    display: none;
    z-index: 1000;
}
.toggle-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
}
.toggle-container .menu-icon {
    height: 20px;
    width: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 600;
}

.toggle-container .menu-icon:before {
    height: 2px;
    width: 100%;
    background-color: #ddd;
    position: absolute;
    content: "";
    transform: translateY(-8px);
    box-shadow: 0 8px #ddd;
    transition: 0.2s;
}
.toggle-container .active.menu-icon:before {
    transform: translateY(0) rotate(45deg);
    box-shadow: none;
}

.toggle-container .menu-icon:after {
    height: 2px;
    width: 100%;
    background: #ddd;
    content: "";
    transform: translateY(8px);
    transition: 0.2s;
}
.toggle-container .active.menu-icon:after {
    transform: translateY(0) rotate(-45deg);
}
.side-menu-profile {
    text-align: center;
    border-bottom: 1px solid #3a3a3a;
    padding-bottom: 26px;
    margin-bottom: 13px;
    display: none;
}
img.sidebar-img {
    display: block;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 50px auto 5px;
    object-fit: cover;
}
h2.sidebar-name {
    color: #ddd;
    font-weight: 500;
}
.side-menu .profile-social a {
    margin: 3px;
}
