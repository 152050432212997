.profile {
    min-height: 100vh;
    overflow-y: auto;
    text-align: center;
    scrollbar-width: thin;
}
.profile::-webkit-scrollbar {
    width: 0px;
}
.profile-img {
    display: block;
    height: 200px;
    width: 200px;
    border-radius: 100%;
    margin: 0 auto 20px;
    object-fit: cover;
}

.profile-name {
    font-size: 34px;
    font-weight: 600;
}

.profile-title {
    letter-spacing: 2px;
    font-weight: 400;
}
.profile-social {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 0;
}
.profile-social a {
    display: flex;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    transition: 0.3s;
    justify-content: center;
    align-items: center;
}
.profile-social a:hover {
    background-color: #555;
}
.resume-btn {
    display: block;
    border: 1px solid #555;
    width: 180px;
    margin: 0 auto;
    padding: 10px;
    margin-bottom: 30px;
    transition: 0.3s;
}
.resume-btn:hover {
    background-color: #555;
}
.profile-body {
    min-height: calc(100vh - 26px);
    padding-top: 50px;
}
.profile-footer {
    font-size: 10px;
    color: #959595;
    padding: 5px 0;
    text-align: center;
}
